export default {
  API_ROOT: "api.sga.gtpdev.com",
  TITLE: "Seminole - Play Slots | Free Coins Daily",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "AOf8tXDtMenz2Ln206KVFbkg6OxqSwAj",
  APPLICATION_TARGET: "sga",
  APPLICATION_NAME: "SGA Dev",
  GOOGLE_CLIENT_ID: "802943799479-qh8k2sighv0gu5l8ifud82ph9p0g11sr.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "dev",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://seminolesocialcasino.onelink.me/AS3n/ef37d6d1",
  HELP_LINK: "seminolesocialcasinohelp.zendesk.com",
  SITE_KEY: "6Le22jcUAAAAAJoEKGduRlplqw7scLoVxpwTjo1n",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "false",
  APPLE_STORE_URL: "https://apps.apple.com/ca/app/SeminoleSocialCasino/id1328651360",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.greentubepro.ssc",
  DSDK_APP_TOKEN: "98eaf7b3-c3d7-4bc9-9b0d-9c075abac8ba",
  DSDK_HASH_KEY: "42D42BCB324A543AACED5",
  DSDK_SV_URL: "https://analytics.bluebat.dive.games/sga",
  DSDK_API_URL: "https://api-sandbox.bluebat.dive.games",
  DSDK_DOMAIN: "sga.gtpdev.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "sga.gtpdev.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "14",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://seminolesocialcasinohelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "true",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "true",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: undefined,
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "TEXT",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: undefined,
  FACEBOOK_LOGIN: "true",
  COMING_SOON: "false",
  MOBILE_WEB_SUPPORT: undefined
};